import { breakpoints } from '@frameio-bs/tokens';
import { CSSProperties } from 'react';

import { SpacerSize } from '~/types';

export type SpacerProps = {
  _key: string;
  _id?: string;
  _type: 'module.spacer';
  sizes?: SpacerSize[];
};

export const toRem = (pixels: number): string => `${pixels / 10}rem`;

const getSpacersValues = (
  sizes: SpacerSize[],
  opts: {
    halfSpacers: boolean;
    unit?: 'rem' | 'px';
  } & (
    | {
        position: string;
        asCustomProperties?: true | never;
      }
    | {
        position?: never;
        asCustomProperties: false;
      }
  ),
) => {
  const options = Object.assign(
    { asCustomProperties: true, unit: 'rem' },
    opts,
  );
  const style: Record<string, string> = {};
  if (sizes && sizes.length) {
    const allBreakpoint = sizes.find((size) => size.breakpoint === 'all');
    let lastHeight = 0;
    for (const breakpoint of breakpoints) {
      const height = allBreakpoint
        ? allBreakpoint.height
        : sizes.find((size) => size.breakpoint === breakpoint.name)?.height ??
          lastHeight;
      lastHeight = height;

      const value = options.halfSpacers ? height * 0.5 : height;

      style[
        options.asCustomProperties
          ? `--sp-h-${options.position}-${breakpoint.name}`
          : breakpoint.name
      ] = options.unit === 'rem' ? toRem(value) : value.toString();
    }
  }
  return style;
};

const getSpacersStyles = (
  beforeSizes: SpacerSize[] = [],
  afterSizes: SpacerSize[] = [],
  halfSpacersTop = true,
  halfSpacersBottom = true,
) => {
  if (beforeSizes.length === 0 && afterSizes.length === 0)
    return {} as CSSProperties;

  // reducing the length of this style since it'll take quite some space in the DOM
  // --spacer-height-BREAKPOINT-before/after
  // -> --sp-h-BREAKPOINT-b/a
  const beforeStyles = getSpacersValues(beforeSizes, {
    halfSpacers: halfSpacersTop,
    position: 'b',
  });
  const afterStyles = getSpacersValues(afterSizes, {
    halfSpacers: halfSpacersBottom,
    position: 'a',
  });

  return {
    ...beforeStyles,
    ...afterStyles,
  } as CSSProperties;
};

export { getSpacersValues };

export default getSpacersStyles;
