import { mergeIntegrations } from '@frameio/segment-ot';

// segment-io types aren't defined
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function getMergedIntegrations(
  options: SegmentAnalytics.SegmentOpts,
) {
  const windowIntegrations =
    typeof window !== 'undefined' && window?.analytics?.options?.integrations;

  return mergeIntegrations(options?.integrations, windowIntegrations);
}
