import { breakpoints } from '@frameio-bs/tokens';

import { Breakpoint } from '~/types';

export const getBreakpoint = (width: number) => {
  let breakpoint: Breakpoint = breakpoints[0];

  let i = 1;
  while (i < breakpoints.length && width >= breakpoints[i].min) {
    i++;
    breakpoint = breakpoints[i - 1];
  }

  return breakpoint;
};

export const getBreakpointFromLabel = (label: string) => {
  return breakpoints.find((breakpoint: Breakpoint) => {
    if (breakpoint.name === label) {
      return breakpoint.min;
    }
  });
};
