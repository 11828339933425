'use client';
import { usePathname } from 'next/navigation';
import { RefObject, useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import UIStore from '~/state/ui';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';

import isNotSmall from '../isNotSmall';
import { tickerAddOnce } from '../ticker';
import { onProgressCallback } from './useScrollProgress.types';

// Defining multiples functions in the same file is not ideal but this is a very small and specific one
const isInView = (progress: number) => progress > 0 && progress < 1;

const useScrollProgress = (
  $element: RefObject<HTMLElement> | undefined,
  onProgress?: onProgressCallback,
  options?: {
    startOnMiddleOfScreen?: boolean;
    finishOnMiddleOfScreen?: boolean;
    runImmediately?: boolean;
    shouldAlwaysComplete?: boolean;
  },
) => {
  const pathname = usePathname();
  const currentProgress = useRef<number | null>(null);
  const isScrollLocked = useRef(false);
  const windowHeight = useRef(0);

  const {
    startOnMiddleOfScreen,
    finishOnMiddleOfScreen,
    shouldAlwaysComplete,
  } = {
    startOnMiddleOfScreen: false,
    finishOnMiddleOfScreen: false,
    shouldAlwaysComplete: true,
    ...options,
  };

  useEffect(() => {
    const unsubscribeScrollLock = UIStore.subscribe(
      (state) => state.isScrollLocked,
      (isLocked) => {
        if (isScrollLocked) {
          isScrollLocked.current = isLocked;
        }
      },
    );

    return () => {
      unsubscribeScrollLock();
    };
  }, []);

  const returnFunction = () => {
    currentProgress.current = null;
  };

  useEffect(() => {
    if (isScrollLocked.current) {
      return returnFunction;
    }
    let total: number;
    const $usedElement = $element;
    if ($usedElement?.current) {
      let unsubscribe: (() => void) | null = null;
      let unsubscribeScroll: (() => void) | null = null;
      let boundingClientRect: DOMRectReadOnly | null = null;

      const observerCallback = (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0];

        // That's the top of the element (because the observer is async, the top on the boundingrect
        // is not the pixel perfect value to we correct it with the scroll position)

        if (entry.isIntersecting) {
          // Our callback to every scroll value change in the store
          const updateProgress = () => {
            if ($element?.current) {
              boundingClientRect = $element.current?.getBoundingClientRect();
              if (boundingClientRect) {
                const maxScroll = UIStore.getState().maxScroll || 0;
                const top =
                  (ScrollPosition.y as number) + boundingClientRect.top;

                const bottom = top + boundingClientRect.height;

                const isElementBottomWithinLastViewportHeight =
                  shouldAlwaysComplete &&
                  top + boundingClientRect.height >
                    maxScroll - windowHeight.current;

                // if the element's bottom is within one viewport height of the page's maxScroll, progress should be set to 1 as the bottom of the element reaches its highest possible point
                total = isElementBottomWithinLastViewportHeight
                  ? boundingClientRect.height + Math.max(maxScroll - bottom, 0)
                  : boundingClientRect.height +
                    Math.min(windowHeight.current, top);

                let startOffset = 0;

                if (startOnMiddleOfScreen) {
                  const offset = Math.min(
                    windowHeight.current,
                    windowHeight.current / 2 + boundingClientRect.height / 2,
                  );
                  startOffset += offset;
                  total -= offset;
                }

                if (finishOnMiddleOfScreen) {
                  const offset = Math.min(
                    windowHeight.current,
                    windowHeight.current / 2 + boundingClientRect.height / 2,
                  );
                  total -= offset;
                }
                // We update the height of the window if we not on small, as on small it's only triggered by
                // the minimizing of the address bar
                if (isNotSmall()) {
                  windowHeight.current = UIStore.getState().windowHeight || 0;
                }
                if (isScrollLocked.current) {
                  return returnFunction;
                }

                if (boundingClientRect) {
                  const scrollY = ScrollPosition.y || 0;
                  const progress =
                    ((scrollY as number) +
                      Math.min(windowHeight.current, top) -
                      top -
                      startOffset) /
                    total;

                  currentProgress.current = Math.max(Math.min(progress, 1), 0);

                  if (onProgress) {
                    onProgress(
                      currentProgress.current,
                      isInView(currentProgress.current) ||
                        (top < windowHeight.current &&
                          scrollY < windowHeight.current),
                      total,
                    );
                  }
                }
              }
            }
          };
          unsubscribe = UIStore.subscribe(
            (state) => [
              state.windowHeight as number,
              state.windowWidth as number,
            ],
            updateProgress,
            { equalityFn: shallow },
          );

          unsubscribeScroll = ScrollPosition.subscribe(updateProgress, {
            fireImmediately: true,
          });
        } else {
          // If we're initializing the progress for the first time (e.g. page load)
          if (currentProgress.current === null) {
            // If the top if positive, the div is below so the progress is 0. Otherwise it's 1
            currentProgress.current = entry.boundingClientRect.top > 0 ? 0 : 1;
            if (onProgress) {
              onProgress(
                currentProgress.current,
                isInView(currentProgress.current),
                total,
              );
            }
          }
          if (unsubscribe instanceof Function) {
            unsubscribe();
          }
          if (unsubscribeScroll instanceof Function) {
            unsubscribeScroll();
          }
          unsubscribe = null;
          unsubscribeScroll = null;
        }
      };

      const observer = new IntersectionObserver(observerCallback);
      observer.observe($usedElement.current);

      tickerAddOnce(() => {
        windowHeight.current = UIStore.getState().windowHeight || 0;
      });

      return () => {
        currentProgress.current = null;
        observer.disconnect();
        if (unsubscribe instanceof Function) {
          unsubscribe();
        }
        if (unsubscribeScroll instanceof Function) {
          unsubscribeScroll();
        }
        returnFunction();
      };
    }
    return returnFunction;
  }, [
    pathname,
    $element,
    onProgress,
    finishOnMiddleOfScreen,
    startOnMiddleOfScreen,
    isScrollLocked,
    shouldAlwaysComplete,
  ]);
};

export default useScrollProgress;
