import { gsap } from 'gsap';

/**
 * Add callback to gsap ticker
 *
 * @param callback - Function that will be called once
 
 */
export const tickerAdd = (
  callback: (time: number, deltaTime: number, frame: number) => void,
) => {
  gsap.ticker.add(callback);
};

/**
 * Add callback to gsap ticker
 *
 * @param callback - Function that will be called once
 
 */
export const tickerRemove = (callback: () => void) => {
  gsap.ticker.remove(callback);
};

/**
 * Add callback to gsap ticker
 *
 * @param callback - Function that will be called once
 * @param prioritize - the callback will be added to the top of the queue instead of the bottom, meaning it'll fire before any of the listeners currently in the queue. This is perfect for if you want your callback to fire before GSAP's global timeline.
 */
export const tickerAddOnce = (callback: () => void, prioritize = false) => {
  gsap.ticker.add(callback, true, prioritize);
};
