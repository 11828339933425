'use client';
import { useEffect, useLayoutEffect } from 'react';

/**
 * UseLayoutEffect logs a nasty warning with SSR. This is an established
 * "hack" to use `useEffect` in SSR instead.
 * Details here: https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a
 */

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export default useIsomorphicLayoutEffect;
