import { gsap } from 'gsap';

const killTimeline = (timeline: GSAPTimeline) => {
  const targets = timeline.getChildren();

  timeline.kill();

  for (let i = 0; i < targets.length; i++) {
    if (targets[i].targets().length) {
      gsap.set(targets[i].targets(), { clearProps: 'all' });
    }
  }
};

export default killTimeline;
