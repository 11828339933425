const hexToComponent = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? result.slice(1).map((value) => parseInt(value, 16) / 255)
    : null;
};

const RGBToHex = (rgb: string) => {
  // Choose correct separator
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  const rgbArray = rgb.split('(')[1].split(')')[0].split(sep);

  let r = (+rgbArray[0]).toString(16),
    g = (+rgbArray[1]).toString(16),
    b = (+rgbArray[2]).toString(16);

  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;

  return '#' + r + g + b;
};

// Stolen & adapted from THREE.js
//https://github.com/mrdoob/three.js/blob/dev/src/math/Color.js#L58
/**
 * convert rgb, rgba, hsl, hsla to array
 * @param color (String) The color value in RGB (for example: rgb(0,0,0) || rgba(0,0,0,0))
 * @returns (Array) array of  normalized color values (from 0 to 1)
 */
const colorToComponent = (colorStr: string) => {
  let color;

  const matches = /^(\w+)\(([^\)]*)\)/.exec(colorStr);
  if (!matches) {
    console.warn('unknown color string');
    return null;
  }

  const name = matches[1];
  const components = matches[2];

  switch (name) {
    case 'rgb':
    case 'rgba':
      if (
        (color =
          /^\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*(\d*\.?\d+)\s*)?$/.exec(
            components,
          ))
      ) {
        // rgb(255,0,0) rgba(255,0,0,0.5)
        return [
          Math.min(255, parseInt(color[1], 10)) / 255,
          Math.min(255, parseInt(color[2], 10)) / 255,
          Math.min(255, parseInt(color[3], 10)) / 255,
          parseFloat(color[4]),
        ];
      }

      if (
        (color =
          /^\s*(\d+)\%\s*,\s*(\d+)\%\s*,\s*(\d+)\%\s*(?:,\s*(\d*\.?\d+)\s*)?$/.exec(
            components,
          ))
      ) {
        // rgb(100%,0%,0%) rgba(100%,0%,0%,0.5)
        return [
          Math.min(100, parseInt(color[1], 10)) / 100,
          Math.min(100, parseInt(color[2], 10)) / 100,
          Math.min(100, parseInt(color[3], 10)) / 100,
          parseFloat(color[4]),
        ];
      }

      break;

    case 'hsl':
    case 'hsla':
      if (
        (color =
          /^\s*(\d*\.?\d+)\s*,\s*(\d*\.?\d+)\%\s*,\s*(\d*\.?\d+)\%\s*(?:,\s*(\d*\.?\d+)\s*)?$/.exec(
            components,
          ))
      ) {
        // hsl(120,50%,50%) hsla(120,50%,50%,0.5)
        return [
          parseFloat(color[1]) / 360,
          parseFloat(color[2]) / 100,
          parseFloat(color[3]) / 100,
          parseFloat(color[4]),
        ];
      }

      break;

    default:
      console.warn('Unknown color model' + colorStr);
  }
};

export { hexToComponent, RGBToHex, colorToComponent };
