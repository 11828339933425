const classNamesComposer = (
  ...classNames: Array<boolean | string | undefined | 0 | null>
): string => {
  // need to break out space-separated flat strings of class names
  const allClassNames = classNames.flatMap(
    (className: boolean | string | undefined | 0 | null) =>
      className && typeof className === 'string'
        ? className.trim().split(' ')
        : [],
  );
  // filter class names that have appropriate characters
  return allClassNames
    .filter(
      (className) =>
        typeof className === 'string' &&
        className.match(new RegExp('^[a-zA-Z0-9_.-]*$')),
    )
    .join(' ');
};

export default classNamesComposer;
