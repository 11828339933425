import { ReactNode } from 'react';

const getNodeText: (node: ReactNode) => string | number | undefined = (
  node,
) => {
  if (typeof node === 'string' || typeof node === 'number') return node;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node && 'props' in node)
    return getNodeText(node.props.children);
};

export default getNodeText;
