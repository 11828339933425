const defaultGradient = {
  offset: [-0.6, 0],
  orientation: 90,
  scale: 1,
  type: "radial",
};

// TODO: remove unused presets

const stickyBackground = {
  rectangleNarrow: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
          scale: 2,
        },
        md: {
          origin: "bottomLeft",
          position: [-10, -10],
          scale: 2,
        },
        lg: {
          origin: "bottomLeft",
          position: [-100, -37],
          scale: 2,
        },
      },
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
          scale: 2,
        },
        md: {
          origin: "topLeft",
          position: [22, -22],
          scale: 2,
        },
        lg: {
          origin: "topLeft",
          position: [-37, -32],
          scale: 2,
        },
      },
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
        },
        md: {
          origin: "topLeft",
          position: [10, 60],
        },
        lg: {
          origin: "topLeft",
          position: [52, -160],
        },
      },
    ],
  },
};
const hardware = {
  rectangleNarrow: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
        },
        md: {
          origin: "topLeft",
          position: [-30, -52],
        },
        lg: {
          origin: "topLeft",
          position: [-48, 95],
        },
      },
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
          hidden: true,
        },
        md: {
          origin: "topLeft",
          position: [10, -32],
        },
        lg: {
          origin: "topLeft",
          position: [35, -78],
        },
      },
      {
        sm: {
          origin: "bottomRight",
          position: [7, 13],
          hidden: true,
        },
        md: {
          origin: "bottomRight",
          position: [-28, -30],
        },
        lg: {
          origin: "bottomRight",
          position: [-70, -100],
        },
      },
    ],
  },
  rectangleWide: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "topRight",
          position: [6, 13],
        },
        md: {
          origin: "bottomLeft",
          position: [-20, -16],
        },
        lg: {
          origin: "bottomLeft",
          position: [-119, -49],
        },
      },
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
          hidden: true,
        },
        md: {
          origin: "topRight",
          position: [93, -10],
        },
        lg: {
          origin: "topRight",
          position: [67, -85],
        },
      },
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
          hidden: true,
        },
        md: {
          origin: "topRight",
          position: [-10, 108],
        },
        lg: {
          origin: "topRight",
          position: [-194, 160],
        },
      },
    ],
  },
  hexagonNarrow: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          origin: "topLeft",
          position: [3, 8],
        },
        md: {
          origin: "bottomLeft",
          position: [-50, -11],
        },
        lg: {
          origin: "bottomLeft",
          position: [8, -55],
        },
      },
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
          hidden: true,
        },
        md: {
          origin: "topRight",
          position: [138, -33],
        },
        lg: {
          origin: "topRight",
          position: [60, -49],
        },
      },
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
          hidden: true,
        },
        md: {
          origin: "topRight",
          position: [10, 78],
        },
        lg: {
          origin: "topRight",
          position: [-68, 142],
        },
      },
    ],
  },
  hexagonWide: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          origin: "topRight",
          position: [-1, 8],
        },
        md: {
          origin: "bottomLeft",
          position: [-20, 11],
        },
        lg: {
          origin: "bottomLeft",
          position: [-102, -73],
        },
      },
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
          hidden: true,
        },
        md: {
          origin: "topRight",
          position: [115, -23],
        },
        lg: {
          origin: "topRight",
          position: [47, -69],
        },
      },
      {
        sm: {
          origin: "topLeft",
          position: [7, 13],
          hidden: true,
        },
        md: {
          origin: "topRight",
          position: [-10, 114],
        },
        lg: {
          origin: "topRight",
          position: [-118, 207],
        },
      },
    ],
  },
};

const customerTile = {
  rectangleUpperRight: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "topRight",
          position: [25, 17],
        },
        md: {
          origin: "topRight",
          position: [0, 0],
        },
        lg: {
          origin: "topRight",
          position: [-143, -83],
        },
      },
    ],
  },
  rectangleLowerLeft: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "bottomLeft",
          position: [0, 0],
        },
        md: {
          origin: "bottomLeft",
          position: [0, 0],
        },
        lg: {
          origin: "bottomLeft",
          position: [-69, -51],
        },
      },
    ],
  },
  hexagonUpperRight: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          origin: "topRight",
          position: [-29, 23],
        },
        md: {
          origin: "topRight",
          position: [-60, -11],
        },
        lg: {
          origin: "topRight",
          position: [-115, -60],
        },
      },
    ],
  },
  hexagonLowerLeft: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          origin: "bottomLeft",
          position: [-27, 10],
        },
        md: {
          origin: "bottomLeft",
          position: [-60, 18],
        },
        lg: {
          origin: "bottomLeft",
          position: [-100, -68],
        },
      },
    ],
  },
};

const bentoCard = {
  rectangle: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        md: {
          origin: "bottomRight",
          position: [-17, -14],
        },
        lg: {
          origin: "topRight",
          position: [-100, -35],
        },
      },
    ],
  },
  hexagon: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        md: {
          origin: "bottomRight",
          position: [-31, -26],
        },
        lg: {
          origin: "topRight",
          position: [-31, -72],
        },
      },
    ],
  },
};

const imageCloud = {
  rectangleUpperLeft: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "topLeft",
          position: [-10, -10],
        },
        md: {
          origin: "topLeft",
          position: [-49, -30],
        },
        lg: {
          origin: "topLeft",
          position: [-32, -30],
        },
      },
    ],
  },
  rectangleUpperRight: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "topRight",
          position: [-9, -7],
        },
        md: {
          origin: "topRight",
          position: [-60, -60],
        },
        lg: {
          origin: "topRight",
          position: [-47, -30],
        },
      },
    ],
  },
  rectangleLowerRight: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "bottomRight",
          position: [-10, -10],
        },
        md: {
          origin: "bottomRight",
          position: [-60, -60],
        },
        lg: {
          origin: "bottomRight",
          position: [-49, -30],
        },
      },
    ],
  },
  rectangleLowerLeft: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "bottomLeft",
          position: [-10, -10],
        },
        md: {
          origin: "bottomLeft",
          position: [-50, -50],
        },
        lg: {
          origin: "bottomLeft",
          position: [-30, -30],
        },
      },
    ],
  },
  hexagonUpperLeft: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          origin: "topLeft",
          position: [-20, -20],
        },
        md: {
          origin: "topLeft",
          position: [-33, -40],
        },
        lg: {
          origin: "topLeft",
          position: [-65, -37],
        },
      },
    ],
  },
  hexagonUpperRight: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          origin: "topRight",
          position: [-39, -20],
        },
        md: {
          origin: "topRight",
          position: [-23, -51],
        },
        lg: {
          origin: "topRight",
          position: [-49, -37],
        },
      },
    ],
  },
  hexagonLowLeft: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          origin: "bottomLeft",
          position: [-20, -28],
        },
        md: {
          origin: "bottomLeft",
          position: [-31, -22],
        },
        lg: {
          origin: "bottomLeft",
          position: [-45, -38],
        },
      },
    ],
  },
  hexagonLowRight: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          origin: "bottomRight",
          position: [-22, -25],
        },
        md: {
          origin: "bottomRight",
          position: [-16, -22],
        },
        lg: {
          origin: "bottomRight",
          position: [-65, -49],
        },
      },
    ],
  },
};

const heroImageWall = {
  rectangle: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "topRight",
          position: [-93, 0],
        },
        md: {
          origin: "topRight",
          position: [-56, 0],
        },
        lg: {
          origin: "topRight",
          position: [-24, 13],
        },
      },
    ],
  },
  hexagon: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          origin: "topRight",
          position: [-32, -13],
          scale: 0.2,
        },
        md: {
          origin: "topRight",
          position: [36, -11],
          scale: 0.2,
        },
        lg: {
          origin: "topRight",
          position: [54, -17],
          scale: 0.2,
        },
      },
    ],
  },
};

const sideBySide = {
  rectangleOne: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "bottomLeft",
          position: [-20, -23],
          scale: 1,
          hidden: false,
        },
        md: {
          origin: "topRight",
          position: [-45, -22],
          scale: 1,
          hidden: false,
        },
        lg: {
          origin: "topRight",
          position: [-40, -83],
          scale: 1,
          hidden: false,
        },
      },
    ],
  },
  hexagonOne: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          origin: "topRight",
          position: [-20, -20],
          scale: 1,
          hidden: false,
        },
        md: {
          origin: "topRight",
          position: [-44, -40],
          scale: 1,
          hidden: false,
        },
        lg: {
          position: [-85, -5],
          origin: "topRight",
          scale: 1,
          hidden: false,
        },
      },
    ],
  },
  rectangleTwo: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        sm: {
          origin: "bottomRight",
          position: [-20, -23],
          scale: 1,
          hidden: false,
        },
        md: {
          origin: "bottomLeft",
          position: [-64, -47],
          scale: 1,
          hidden: false,
        },
        lg: {
          origin: "bottomLeft",
          position: [-100, -100],
          scale: 1,
          hidden: false,
        },
      },
      {
        sm: {
          origin: "topRight",
          position: [0, 0],
          hidden: true,
          scale: 1,
        },
        md: {
          origin: "topRight",
          position: [-40, -20],
          hidden: false,
          scale: 1,
        },
        lg: {
          origin: "topRight",
          position: [-100, -100],
          hidden: false,
          scale: 1,
        },
      },
    ],
  },
  hexagonTwo: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        sm: {
          position: [-22, -31],
          origin: "bottomLeft",
          hidden: false,
        },
        md: {
          position: [-69, -65],
          origin: "bottomLeft",
          hidden: false,
          scale: 1,
        },
        lg: {
          position: [-89, -84],
          origin: "bottomLeft",
          hidden: false,
          scale: 1,
        },
      },
      {
        sm: {
          position: [-10, -10],
          origin: "topRight",
          hidden: true,
        },
        md: {
          position: [-36, -37],
          origin: "topRight",
          hidden: false,
          scale: 1,
        },
        lg: {
          position: [-85, -5],
          origin: "topRight",
          scale: 1,
          hidden: false,
        },
      },
    ],
  },
};

const speedbump = {
  // CTA
  rectangle: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        lg: {
          origin: "bottomRight",
          position: [-71, -60],
        },
      },
    ],
  },
  hexagon: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        lg: {
          origin: "bottomRight",
          position: [-100, -108],
        },
      },
    ],
  },
  // Social proof
  rectangleWide: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        md: {
          origin: "bottomLeft",
          position: [-15, -20],
        },
        lg: {
          origin: "bottomLeft",
          position: [-57, -68],
        },
      },
      {
        md: {
          origin: "topRight",
          position: [-25, -25],
        },
        lg: {
          origin: "topRight",
          position: [-108, -88],
        },
      },
    ],
  },
  rectangleNarrow: {
    gradient: defaultGradient,
    shapeType: "rectangle",
    shapes: [
      {
        md: {
          origin: "bottomLeft",
          position: [0, 0],
        },
        lg: {
          origin: "bottomLeft",
          position: [-17, -34],
        },
      },
      {
        md: {
          origin: "topRight",
          position: [2, 2],
        },
        lg: {
          origin: "topRight",
          position: [-28, -28],
        },
      },
    ],
  },
  hexagonWide: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        md: {
          origin: "bottomLeft",
          position: [18, -33],
        },
        lg: {
          origin: "bottomLeft",
          position: [-12, -95],
        },
      },
      {
        md: {
          origin: "topRight",
          position: [-44, -95],
        },
        lg: {
          origin: "topRight",
          position: [16, -31],
        },
      },
    ],
  },
  hexagonNarrow: {
    gradient: defaultGradient,
    shapeType: "hexagon",
    shapes: [
      {
        md: {
          origin: "bottomLeft",
          position: [-13, 28],
        },
        lg: {
          origin: "bottomLeft",
          position: [28, -70],
        },
      },
      {
        md: {
          origin: "topRight",
          position: [2, 41],
        },
        lg: {
          origin: "topRight",
          position: [-35, -35],
        },
      },
    ],
  },
};

/*
exporting these tokens with correct JS doc annotation so they can be read as ts and made into union types
https://stackoverflow.com/questions/74184312/how-can-i-build-a-typescript-string-literal-type-with-an-imported-array
*/
const glows = /** @type {const} */ ({
  stickyBackground,
  hardware,
  customerTile,
  bentoCard,
  imageCloud,
  heroImageWall,
  sideBySide: { ...sideBySide, ...hardware },
  speedbump,
});

module.exports = glows;
