import tokens from '@frameio-bs/tokens';

import { SpacerSizeNumber } from '~/types';

/**
 * takes a number value and returns the closest spacer size
 */
const getSpacer = (value: number): SpacerSizeNumber => {
  return tokens.spacers.reduce(
    (previous: SpacerSizeNumber, current: SpacerSizeNumber) => {
      const previousDiff = Math.abs(previous - value);
      const currentDiff = Math.abs(current - value);

      if (previousDiff == currentDiff) {
        return previous > current ? previous : current;
      } else {
        return currentDiff < previousDiff ? current : previous;
      }
    },
  ) as SpacerSizeNumber;
};

export default getSpacer;
