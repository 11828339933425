import tokens from '@frameio-bs/tokens';

import { Breakpoint } from '~/types';
import Easing, { EaseType } from '~/utils/singletons/Easing';

type KeenSliderBreakpoint = {
  //  "(min-width: 600px)"
  mediaQuery: string;
  // 600
  value: number;
};
export type KeenSliderBreakpoints = {
  sm: KeenSliderBreakpoint;
  md: KeenSliderBreakpoint;
  lg: KeenSliderBreakpoint;
};

const breakpoints: KeenSliderBreakpoints = tokens.breakpoints.reduce(
  (previous: KeenSliderBreakpoints, current: Breakpoint) => {
    return {
      ...previous,
      [current.name]: {
        mediaQuery: `(min-width: ${current.min}px)`,
        value: current.min,
      },
    };
  },
  // cast it as KeenSliderBreakpoints for type issue
  {} as KeenSliderBreakpoints,
);

const defaultAnimation = {
  duration: 400,
  easing: Easing.getEasingFunction(EaseType.BASIC_BUTTER),
};

const defaultConfig = {
  defaultAnimation,
  dragSpeed: 0.5,
  initial: 0,
};

const keenSliderConfig = {
  breakpoints,
  defaultConfig,
};

export default keenSliderConfig;
