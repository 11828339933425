import { tickerAddOnce } from '../../ticker';
import {
  ScrollDirection,
  ScrollPositionSubscriber,
  ScrollPositionValue,
} from './ScrollPosition.types';

class ScrollPosition {
  x: ScrollPositionValue;
  y: ScrollPositionValue;
  direction: ScrollDirection;
  prevScrollY: number;
  subscribers: ScrollPositionSubscriber[];
  boundHandleUpdatePosition: () => void;
  isActive: boolean;

  constructor() {
    this.x = null;
    this.y = null;
    this.direction = null;
    this.prevScrollY = 0;
    this.subscribers = [];

    this.boundHandleUpdatePosition = this.handleUpdatePosition.bind(this);

    this.isActive = false;
    if (typeof window !== 'undefined') {
      this.handleUpdatePosition(true);
    }
    if (typeof window !== 'undefined' && window.document) {
      this.addEventListeners();
    }
  }

  addEventListeners() {
    this.isActive = true;
    document.addEventListener('scroll', this.boundHandleUpdatePosition);
  }

  removeEventListeners() {
    this.isActive = false;
    document.removeEventListener('scroll', this.boundHandleUpdatePosition);
  }

  handleUpdatePosition(force = false) {
    if (this.isActive || force) {
      tickerAddOnce(() => this.updatePosition(), true);
    }
  }

  updatePosition() {
    const x = this.x;
    const y = window.scrollY;
    let direction = 0;

    if (y < this.prevScrollY) {
      direction = -1;
    } else {
      direction = 1;
    }

    this.setPosition(x, y, direction);

    for (let i = 0; i < this.subscribers.length; i++) {
      this.subscribers[i]({ x, y, direction });
    }

    this.prevScrollY = y;
  }

  subscribe(
    callback: ScrollPositionSubscriber,
    { fireImmediately }: { fireImmediately?: boolean } = {},
  ) {
    this.subscribers.push(callback);
    if (fireImmediately) {
      callback({ y: this.y, x: this.x, direction: this.direction });
    }
    return () => {
      this.subscribers = this.subscribers.filter((cb) => cb !== callback);
    };
  }

  getPosition() {
    return {
      x: this.x,
      y: this.y,
    };
  }

  setPosition(
    x: ScrollPositionValue,
    y: ScrollPositionValue,
    direction: ScrollDirection,
  ) {
    this.x = x;
    this.y = y;
    this.direction = direction;
  }
}

const instance = new ScrollPosition();

export default instance;
