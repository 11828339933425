import debounce from 'lodash/debounce';

import { tickerAddOnce } from '../ticker';

type maxScroll = number | null;

type size = {
  maxScroll: maxScroll;
};

type subscriber = (size: size) => void;

class BodySize {
  maxScroll: maxScroll | null;
  subscribers: subscriber[];
  resizeObserver: ResizeObserver | null;
  boundUpdateSize: () => void;
  isActive: boolean;

  constructor() {
    this.maxScroll =
      typeof window !== 'undefined' && document.body
        ? document.body.scrollHeight
        : null;

    this.subscribers = [];
    this.boundUpdateSize = debounce(this.updateSize.bind(this), 300);

    this.resizeObserver =
      typeof window !== 'undefined' && window.document
        ? new ResizeObserver((entries) => {
            for (const entry of entries) {
              if (entry.contentBoxSize) {
                this.boundUpdateSize();
              }
            }
          })
        : null;

    this.isActive = false;
    if (typeof window !== 'undefined' && window.document) {
      this.addEventListeners();
    }
  }

  addEventListeners() {
    this.isActive = true;
    this.resizeObserver?.observe(document.body);
  }

  removeEventListeners() {
    this.isActive = false;
    this.resizeObserver?.unobserve(document.body);
  }

  updateSize() {
    if (this.isActive) {
      const bodySizeUpdateInTicker = () => {
        const maxScroll = document.body.scrollHeight;

        if (this.maxScroll !== maxScroll) {
          this.setSize(maxScroll);
          for (const subscriber of this.subscribers) {
            subscriber({ maxScroll });
          }
        }
      };

      tickerAddOnce(bodySizeUpdateInTicker, true);
    }
  }

  subscribe(callback: subscriber) {
    this.subscribers.push(callback);
    return () => {
      this.subscribers = this.subscribers.filter((cb) => cb !== callback);
    };
  }

  getSize() {
    return {
      maxScroll: this.maxScroll,
    };
  }

  setSize(maxScroll: maxScroll) {
    this.maxScroll = maxScroll;
  }
}

export default new BodySize();
