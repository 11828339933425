import { subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { Breakpoint } from '~/types';
import { getBreakpoint } from '~/utils';
import BodySize from '~/utils/domEvents/BodySize';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';
import WindowSize from '~/utils/domEvents/WindowSize';
import AnimationTracker from '~/utils/singletons/AnimationTracker';

type UIStore = {
  breakpoint: Breakpoint | null;
  hlsSupport: boolean;
  isComputedStyleComplete: boolean;
  isDesktopNavigationShowing: boolean;
  isNavigationDisabled: boolean;
  disableNavigation: () => void;
  isNavigationOpaque: boolean;
  isNavigationBarVisible: boolean;
  setIsNavigationBarVisible: (value: boolean) => void;
  navigationHeight: number | null;
  isScrollLocked: boolean;
  currentFullscreenVideoSrc: string | null;
  scrollDirection: number | null;
  scrollX: number | null;
  scrollY: number | null;
  scrollPaddingTop: number;
  setComputedStyleComplete: (value: boolean) => void;
  setCurrentFullscreenVideoSrc: (value: string | null) => void;
  setHlsSupport: (value: boolean) => void;
  setIsDesktopNavigationOpaque: (value: HTMLElement) => void;
  setIsDesktopNavigationShowing: (value: boolean) => void;
  setIsScrollLocked: (value: boolean) => void;
  setNavigationHeight: (value: number) => void;
  setScrollPaddingTop: (value: number) => void;
  unsetIsDesktopNavigationOpaque: (value: HTMLElement) => void;
  windowHeight: number | null;
  windowWidth: number | null;
  maxScroll: number | null;
  bannerDismissed: boolean;
  setBannerDismissed: (value: boolean) => void;
  bannerHeight: number;
  setBannerHeight: (value: number) => void;
  pageSectionsRefs: HTMLDivElement[];
  setPageSectionsRefs: (el: HTMLDivElement, index: number) => void;
  webglDisabled: boolean;
  prefersReducedMotion: boolean;
  setPrefersReducedMotion: (value: boolean) => void;
  hasPendingPriorityAnimations: boolean;
};

const navigationOpaqueItems = [] as HTMLElement[];
const pageSectionsEls = [] as HTMLDivElement[];

const UIStore = createWithEqualityFn<UIStore>()(
  subscribeWithSelector((set) => ({
    breakpoint:
      WindowSize.width !== null ? getBreakpoint(WindowSize.width) : null,
    disableNavigation: () => set({ isNavigationDisabled: true }),
    currentFullscreenVideoSrc: null,
    hlsSupport: true,
    isComputedStyleComplete: false,
    isDesktopNavigationShowing: true,
    isNavigationDisabled: false,
    isNavigationOpaque: false,
    navigationHeight: 0,
    isNavigationBarVisible: true,
    setIsNavigationBarVisible: (isNavigationBarVisible: boolean) =>
      set({ isNavigationBarVisible }),
    isScrollLocked: false,
    scrollDirection: ScrollPosition.direction,
    scrollPaddingTop: 0,
    scrollX: ScrollPosition.x,
    scrollY: ScrollPosition.y,
    setComputedStyleComplete: (value = true) =>
      set({ isComputedStyleComplete: value }),
    setCurrentFullscreenVideoSrc: (currentFullscreenVideoSrc: string | null) =>
      set({ currentFullscreenVideoSrc }),
    setHlsSupport: (hlsSupport: boolean) => set({ hlsSupport }),
    setIsDesktopNavigationOpaque: (value: HTMLElement) => {
      if (navigationOpaqueItems.indexOf(value) < 0) {
        navigationOpaqueItems.push(value);
      }
      return set({ isNavigationOpaque: true });
    },
    setIsDesktopNavigationShowing: (value: boolean) =>
      set({ isDesktopNavigationShowing: value }),
    setIsScrollLocked: (value: boolean) => set({ isScrollLocked: value }),
    setNavigationHeight: (value: number) => set({ navigationHeight: value }),
    setScrollPaddingTop: (value: number) => set({ scrollPaddingTop: value }),
    unsetIsDesktopNavigationOpaque: (value: HTMLElement) => {
      const pos = navigationOpaqueItems.indexOf(value);
      if (pos >= 0) {
        navigationOpaqueItems.splice(pos, 1);
      }
      const isNavigationOpaque = navigationOpaqueItems.length > 0;
      return set({ isNavigationOpaque });
    },
    windowHeight: WindowSize.height,
    windowWidth: WindowSize.width,
    maxScroll: BodySize.maxScroll,
    bannerDismissed: false,
    setBannerDismissed: (value: boolean) => set({ bannerDismissed: value }),
    bannerHeight: 0,
    setBannerHeight: (value: number) => set({ bannerHeight: value }),
    pageSectionsRefs: [],
    setPageSectionsRefs: (el: HTMLDivElement, index: number) => {
      pageSectionsEls[index] = el;
      return set({ pageSectionsRefs: pageSectionsEls });
    },
    webglDisabled: false,
    prefersReducedMotion: false,
    setPrefersReducedMotion: (prefersReducedMotion: boolean) =>
      set({ prefersReducedMotion }),
    hasPendingPriorityAnimations: false,
  })),
  shallow,
);

AnimationTracker.subscribe((hasPending) => {
  UIStore.setState({ hasPendingPriorityAnimations: hasPending });
});

WindowSize.subscribe((size) => {
  const patch: {
    windowHeight?: number | null;
    windowWidth?: number | null;
    breakpoint?: Breakpoint | null;
  } = {
    windowHeight: UIStore.getState().windowHeight,
    windowWidth: UIStore.getState().windowWidth,
    breakpoint: UIStore.getState().breakpoint,
  };
  const currentState = UIStore.getState();
  if (size.height && size.height !== currentState.windowHeight) {
    patch.windowHeight = size.height;
  }
  if (size.width && size.width !== currentState.windowWidth) {
    patch.windowWidth = size.width;
    const breakpoint = getBreakpoint(size.width);
    if (breakpoint !== currentState.breakpoint) {
      patch.breakpoint = breakpoint;
    }
  }
  UIStore.setState(
    patch as {
      windowHeight: number;
      windowWidth: number;
      breakpoint: Breakpoint;
    },
  );
});

BodySize.subscribe((size) => {
  const patch: {
    maxScroll?: number | null;
  } = {};
  const currentState = UIStore.getState();

  if (size.maxScroll !== currentState.maxScroll) {
    patch.maxScroll = size.maxScroll;
  }

  UIStore.setState(
    patch as {
      maxScroll: number;
    },
  );
});

export default UIStore;
