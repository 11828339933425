'use client';
import { RefObject, useEffect, useState } from 'react';

import getUniqueId from '~/utils/getUniqueId';

const useIsInView = (
  $element: RefObject<HTMLElement>,
  defaultValue = false,
  triggerOnce = false,
) => {
  const [isVisible, updateIsVisible] = useState<boolean>(defaultValue);

  useEffect(() => {
    if (!defaultValue) {
      // This function is empty on purpose, to have a variable non-breaking that
      // will then be replaced by the actual one when the singleton is loaded
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      let unsubscribe = () => {};

      async function initObserver() {
        if ($element.current === null) return;
        const id = getUniqueId('glow');
        ($element.current as HTMLElement).setAttribute('data-id', id);
        const Observer = (await import('./intersectionObserverSingleton'))
          .default;
        unsubscribe = Observer.subscribe(
          $element.current,
          id,
          (visible: boolean) => {
            if (triggerOnce && visible) {
              unsubscribe();
            }
            updateIsVisible(visible);
          },
        );
      }
      initObserver();

      return unsubscribe;
    }
  }, [$element, defaultValue, triggerOnce]);

  return isVisible;
};

export default useIsInView;
