import { DictionaryData } from '~/types/settings';

export type DictionaryStore = { [key in DictionaryData]: string };

const PREFIX = '{{';
const SUFFIX = '}}';
const regexStr = `${PREFIX}(.+?)${SUFFIX}`;

export const regexp = new RegExp(regexStr, 'g');

export type DictionaryEntry = {
  key: DictionaryData;
  label: string;
};
