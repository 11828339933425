const colors = [
  { name: "Turquoise Triumph", value: "rgba(97, 153, 246, 1)", accent: true },
  { name: "Cobalt", value: "rgba(91, 83, 255, 1)", accent: true },
  { name: "Cobalt 20", value: "rgba(91, 83, 255, 0.2)", accent: true },
  { name: "Cobalt Light", value: "rgba(99, 105, 255, 1)", accent: true },
  { name: "Cobalt Dark", value: "rgba(82, 55, 249, 1)", accent: true },
  { name: "Electric", value: "rgba(71, 235, 235, 1)", accent: true },
  { name: "Electric Light", value: "rgba(135, 255, 255, 1)", accent: true },
  { name: "Electric Dark", value: "rgba(0, 184, 185, 1)", accent: true },
  { name: "Iris", value: "rgba(137, 82, 253, 1)", accent: true },
  { name: "Iris Light", value: "rgba(159, 117, 249, 1)", accent: true },
  { name: "Iris Dark", value: "rgba(99, 44, 218, 1)", accent: true },
  { name: "Coral", value: "rgba(252, 133, 109, 1)", accent: true },
  { name: "Coral Light", value: "rgba(252, 164, 147, 1)", accent: true },
  { name: "Coral Dark", value: "rgba(227, 107, 82, 1)", accent: true },
  { name: "Grey 00", value: "rgba(0, 0, 0, 1)" },
  { name: "Grey 05", value: "rgba(13, 13, 24, 1)" },
  { name: "Grey 08", value: "rgba(18, 19, 29, 1)" },
  { name: "Grey 10", value: "rgba(22, 23, 34, 1)" },
  { name: "Grey 12", value: "rgba(29, 31, 44, 1)" },
  { name: "Grey 15", value: "rgba(37, 39, 54, 1)" },
  { name: "Grey 20", value: "rgba(46, 47, 64, 1)" },
  { name: "Grey 30", value: "rgba(54, 56, 74, 1)" },
  { name: "Grey 40", value: "rgba(79, 81, 103, 1)" },
  { name: "Grey 50", value: "rgba(106, 107, 131, 1)" },
  { name: "Grey 60", value: "rgba(129, 133, 159, 1)", accent: true },
  { name: "Grey 70", value: "rgba(163, 164, 191, 1)" },
  { name: "Grey 80", value: "rgba(193, 194, 217, 1)" },
  { name: "Grey 88", value: "rgba(213, 214, 234, 1)" },
  { name: "Grey 90", value: "rgba(222, 223, 238, 1)" },
  { name: "Grey 93", value: "rgba(232, 233, 243, 1)" },
  { name: "Grey 95", value: "rgba(240, 240, 247, 1)" },
  { name: "Grey 97", value: "rgba(245, 245, 248, 1)" },
  { name: "Grey 100", value: "rgba(252, 252, 252, 1)", accent: true },
  { name: "Alpha Light 90", value: "rgba(241, 244, 255, 0.9)" },
  { name: "Alpha Light 80", value: "rgba(240, 240, 255, 0.8)" },
  { name: "Alpha Light 70", value: "rgba(233, 233, 255, 0.7)" },
  { name: "Alpha Light 60", value: "rgba(234, 234, 255, 0.6)" },
  { name: "Alpha Light 50", value: "rgba(234, 234, 255, 0.5)" },
  { name: "Alpha Light 40", value: "rgba(228, 228, 255, 0.4)" },
  { name: "Alpha Light 30", value: "rgba(220, 220, 255, 0.3)" },
  { name: "Alpha Light 20", value: "rgba(209, 209, 251, 0.2)" },
  { name: "Alpha Light 15", value: "rgba(209, 209, 251, 0.15)" },
  { name: "Alpha Light 10", value: "rgba(189, 189, 244, 0.1)" },
  { name: "Alpha Light 5", value: "rgba(157, 157, 255, 0.05)" },
  { name: "Alpha Dark 90", value: "rgba(13, 13, 24, 0.9)" },
  { name: "Alpha Dark 80", value: "rgba(13, 13, 24, 0.8)" },
  { name: "Alpha Dark 70", value: "rgba(13, 13, 24, 0.7)" },
  { name: "Alpha Dark 60", value: "rgba(13, 13, 24, 0.6)" },
  { name: "Alpha Dark 50", value: "rgba(13, 13, 24, 0.5)" },
  { name: "Alpha Dark 40", value: "rgba(13, 13, 24, 0.4)" },
  { name: "Alpha Dark 30", value: "rgba(13, 13, 24, 0.3)" },
  { name: "Alpha Dark 20", value: "rgba(13, 13, 24, 0.2)" },
  { name: "Alpha Dark 10", value: "rgba(13, 13, 24, 0.1)" },
  { name: "Alpha Dark 5", value: "rgba(13, 13, 24, 0.05)" },
  { name: "Alpha Black 100", value: "rgba(0, 0, 0, 1)" },
  { name: "Alpha Black 90", value: "rgba(0, 0, 0, 0.9)" },
  { name: "Alpha Black 80", value: "rgba(0, 0, 0, 0.8)" },
  { name: "Alpha Black 70", value: "rgba(0, 0, 0, 0.7)" },
  { name: "Alpha Black 60", value: "rgba(0, 0, 0, 0.6)" },
  { name: "Alpha Black 50", value: "rgba(0, 0, 0, 0.5)" },
  { name: "Alpha Black 40", value: "rgba(0, 0, 0, 0.4)" },
  { name: "Alpha Black 30", value: "rgba(0, 0, 0, 0.3)" },
  { name: "Alpha Black 25", value: "rgba(0, 0, 0, 0.25)" },
  { name: "Alpha Black 20", value: "rgba(0, 0, 0, 0.2)" },
  { name: "Alpha Black 10", value: "rgba(0, 0, 0, 0.1)" },
  { name: "Alpha Black 5", value: "rgba(0, 0, 0, 0.05)" },
  { name: "Alpha Hawkes 20", value: "rgba(209, 209, 251, 0.2)" },
  { name: "Alpha Hawkes 60", value: "rgba(209, 209, 251, 0.6)" },
  { name: "Alpha Cinder 50", value: "rgba(13, 13, 24, 0.5)" },
  { name: "Alpha Perano 10", value: "rgba(189, 189, 244, 0.1)" },
  { name: "Alpha Grey 30", value: "rgba(54, 56, 74, 0.2)" },
  { name: "Alpha Grey 80", value: "rgba(46, 47, 64, 0.8)" },
];
module.exports = colors;
