const buttonColorSchemes = [
  {
    value: "solidLight",
    title: "Solid Light (Grey 97)",
  },
  {
    value: "solidDark",
    title: "Solid Dark (Grey 15)",
  },
  {
    value: "solidLoud",
    title: "Solid Loud (Cobalt)",
  },
  {
    value: "glassDark",
    title: "Glass Dark (Alpha Perano 10)",
  },
  {
    value: "glassClear",
    title: "Glass Clear (Alpha Light 10)",
  },
  {
    value: "glassFog",
    title: "Glass Fog (Alpha Light 30)",
  },
  {
    value: "glassSmoke",
    title: "Glass Smoke (Alpha Dark 50)",
  },
];

module.exports = buttonColorSchemes;
