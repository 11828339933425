import { DictionaryData } from '~/types/settings';

import { DictionaryStore, regexp } from './types';

class Dictionary {
  isInitialized = false;
  private store!: DictionaryStore;

  initialize(data: DictionaryStore) {
    if (!this.isInitialized) {
      this.isInitialized = true;
      this.store = data;
    }
  }

  getStore(): DictionaryStore {
    return { ...this.store };
  }
}

const instance = new Dictionary();

/**
 * Gets a dictionary entry composed with variables if provided
 * @param key The dictionary key
 * @param vars Variables to compose the string
 * @returns The label as a string
 * @example dict('openModal')
 */
const dict = (key: DictionaryData, vars?: { [key: string]: string }) => {
  let label = instance.getStore()[key];

  if (!vars) return label;

  const matches = [...label.matchAll(regexp)];

  matches.forEach((match: RegExpMatchArray) => {
    const value = match[1].trim();
    if (vars[value]) {
      label = label.replace(match[0], escape(vars[value]));
    }
  });

  return label || key;
};

export default instance;

export { dict };
