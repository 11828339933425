const breakpoints = /** @type {const} */([
  {
    // from 0 - 767 we apply the base/small breakpoint
    name: "sm",
    min: 0,
  },
  {
    // Tablet - starts at 768
    name: "md",
    min: 768,
  },
  {
    // Desktop - starts at 1240
    name: "lg",
    min: 1240,
  },
  {
    // Desktop XL - starts at 1921
    name: "xl",
    min: 1921,
  },
]);

breakpoints.sort((a, b) =>
  a.min === null ? 1 : b === null ? -1 : a.min - b.min
);

module.exports = breakpoints;
