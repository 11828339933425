const createRoundedSvgMask = ({
  hasRoundedTop,
  hasRoundedBottom,
  cornerRadius,
}: {
  hasRoundedTop: boolean;
  hasRoundedBottom: boolean;
  cornerRadius: string;
}) => {
  return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><rect width="100%" height="100%" rx="${cornerRadius}" ry="${cornerRadius}" fill="black" />${
    hasRoundedTop && hasRoundedBottom
      ? ''
      : `<rect width="100%" height="${
          hasRoundedBottom ? `calc(100% - ${cornerRadius})` : '100%'
        }" y="${hasRoundedTop ? cornerRadius : '0'}" fill="black" />`
  }</svg>')`;
};

export default createRoundedSvgMask;
