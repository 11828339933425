/*
exporting these tokens with correct JS doc annotation so they can be read as ts and made into union types
https://stackoverflow.com/questions/74184312/how-can-i-build-a-typescript-string-literal-type-with-an-imported-array
*/
const overlays = /** @type {const} */ ([
  { title: "None", value: "none", css: "none" },
  { title: "Dim", value: "dim", css: "rgb(0 0 0 / 20%)" },
  { title: "Dim-40", value: "dim40", css: "rgb(0 0 0 / 40%)" },
  { title: "Dim-60", value: "dim60", css: "rgb(0 0 0 / 60%)" },
  { title: "Dim-80", value: "dim80", css: "rgb(0 0 0 / 80%)" },
  {
    title: "Light",
    value: "light",
    css: "linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), linear-gradient(18.61deg, rgb(0 0 0 / 50.4%) 21.85%, rgb(0 0 0 / 0%) 73.55%)",
  },
  {
    title: "Medium",
    value: "medium",
    css: "linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), linear-gradient(18.61deg, rgb(0 0 0 / 67.2%) 21.85%, rgb(0 0 0 / 0%) 73.55%)",
  },
  {
    title: "Heavy",
    value: "heavy",
    css: "linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), linear-gradient(180deg, rgb(0 0 0 / 0%) 50%, #000000 100%), linear-gradient(7.96deg, #000000 14.71%, rgb(0 0 0 / 0%) 78.17%)",
  },
]);
module.exports = overlays;
