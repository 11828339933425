import throttle from 'lodash/throttle';

import UIStore from '~/state/ui';

import { tickerAddOnce } from '../ticker';

type Width = number | null;
type Height = number | null;

type Size = {
  width: Width;
  height: Height;
};

type subscriber = (size: Partial<Size>) => void;

class WindowSize {
  width: Width | null;
  height: Height | null;
  subscribers: subscriber[];
  // boundUpdateSize: (size: Partial<Size>) => void;
  boundUpdateHeight: (entries: ResizeObserverEntry[]) => void;
  boundUpdateWidth: (entries: ResizeObserverEntry[]) => void;
  isActive: boolean;

  constructor() {
    // We're using clientWidth on body to exclude the scrollbar width
    this.width =
      typeof window !== 'undefined' && window.document
        ? document.body.clientWidth
        : null;

    // The client width is usually not yet computed on load,
    // so we're polling until it's a valid value
    if (this.width === 0) {
      const update = () => {
        const width = document
          .getElementById('#horizontal-sizer')
          ?.getBoundingClientRect().width;
        const height = document
          .getElementById('#vertical-sizer')
          ?.getBoundingClientRect().width;
        this.updateSize({ width, height });
        if (this.width === 0 || typeof this.width !== 'number') {
          requestAnimationFrame(update);
        }
      };
      requestAnimationFrame(update);
    }

    this.height =
      typeof window !== 'undefined' && window.document
        ? window.innerHeight
        : null;

    this.subscribers = [];

    this.boundUpdateHeight = throttle(this.updateHeight.bind(this), 300);
    this.boundUpdateWidth = throttle(this.updateWidth.bind(this), 300);

    this.isActive = false;
    if (typeof window !== 'undefined' && window.document) {
      this.addEventListeners();
    }
  }

  addEventListeners() {
    this.isActive = true;

    const $verticalSizer = document.getElementById('vertical-sizer');
    const $horizontalSizer = document.getElementById('horizontal-sizer');
    if ($verticalSizer && $horizontalSizer) {
      const verticalObserver = new ResizeObserver(this.boundUpdateHeight);
      verticalObserver.observe($verticalSizer);
      const horizontalObserver = new ResizeObserver(this.boundUpdateWidth);
      horizontalObserver.observe($horizontalSizer);
    } else {
      requestAnimationFrame(() => this.addEventListeners());
    }
  }

  removeEventListeners() {
    this.isActive = false;
  }

  updateHeight(entries: ResizeObserverEntry[]) {
    if (
      entries[0] &&
      (UIStore.getState().breakpoint?.name !== 'sm' || this.height === null)
    ) {
      this.updateSize({ height: entries[0].contentRect.height });
    }
  }

  updateWidth(entries: ResizeObserverEntry[]) {
    if (entries[0]) {
      this.updateSize({ width: entries[0].contentRect.width });
    }
  }

  updateSize(size: { width?: number; height?: number }) {
    if (this.isActive) {
      const windowSizeUpdateInTicker = () => {
        this.setSize(size);
        for (const subscriber of this.subscribers) {
          subscriber(size);
        }
      };

      tickerAddOnce(windowSizeUpdateInTicker, true);
    }
  }

  subscribe(callback: subscriber) {
    this.subscribers.push(callback);
    return () => {
      this.subscribers = this.subscribers.filter((cb) => cb !== callback);
    };
  }

  getSize() {
    return {
      width: this.width,
      height: this.height,
    };
  }

  setSize(size: Partial<Size>) {
    if (size.height) {
      this.height = size.height;
    }
    if (size.width) {
      this.width = size.width;
    }
  }
}

export default new WindowSize();
