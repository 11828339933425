import { breakpoints } from '@frameio-bs/tokens';

import { Breakpoint } from '~/types';

/**
 * Determines if the provided breakpoint is greater than or equal to a specified breakpoint.
 *
 * This function checks if the minimum value of the given `breakpoint` object is greater than or
 * equal to the minimum value of the breakpoint identified by `breakpointToMatch`.
 *
 * @param {Breakpoint} breakpoint - An object representing a breakpoint. This object should have a
 *                                  `min` property that defines the minimum width for the breakpoint.
 * @param {string} breakpointToMatch - The name of the breakpoint to compare against. This function
 *                                     will search for this breakpoint in the global `breakpoints` array from the tokens
 *                                     and compare its `min` value with that of the `breakpoint` parameter.
 *
 * @returns {boolean} - Returns `true` if `breakpoint`'s minimum value is greater than or equal to the
 *                      minimum value of the breakpoint named `breakpointToMatch`. Returns `false` if
 *                      `breakpoint` is nullish or if its minimum value is less.
 *
 * @example
 * // Check if the 'md' breakpoint is greater than or equal to 'sm'
 * isBreakpointOrGreater({ name: 'md', min: 768 }, 'sm'); // Returns true
 *
 * // Check if the 'small' breakpoint is greater than or equal to 'md'
 * isBreakpointOrGreater({ name: 'lg', min: 1024 }, 'sm'); // Returns false
 */
function isBreakpointOrGreater(
  breakpoint: Breakpoint | null,
  breakpointToMatch: string,
): boolean {
  if (breakpoint === null) {
    return true;
  }
  const isBreakpointOrGreater =
    (breakpoint?.min ?? false) &&
    breakpoint.min >=
      breakpoints.filter(
        (breakpoint) => breakpoint.name === breakpointToMatch,
      )[0].min;

  return isBreakpointOrGreater === true;
}

export default isBreakpointOrGreater;
