import { enqueueOrRun } from '@frameio/segment-ot';

import getMergedIntegrations from './getMergedIntegrations';

const trackEventSegment = (
  eventName: string,
  properties: Record<string, string>,
  options?: SegmentAnalytics.SegmentOpts,
) => {
  enqueueOrRun(
    () =>
      window.analytics &&
      window.analytics.track(eventName, properties, {
        integrations: getMergedIntegrations(options?.integrations),
      }),
  );
};

export default trackEventSegment;
