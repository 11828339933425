import { breakpoints } from '@frameio-bs/tokens';

import { Breakpoint } from '~/types';

/**
 * Determines if the provided breakpoint is less than or equal to a specified breakpoint.
 *
 * This function checks if the minimum value of the given `breakpoint` object is less than or
 * equal to the minimum value of the breakpoint identified by `breakpointToMatch`. The function
 * assumes the existence of a global `breakpoints` array that contains objects with `name` and `min`
 * properties.
 *
 * @param {Breakpoint} breakpoint - An object representing a breakpoint. This object should have a
 *                                  `min` property that defines the minimum width for the breakpoint.
 * @param {string} breakpointToMatch - The name of the breakpoint to compare against. This function
 *                                     will search for this breakpoint in the global `breakpoints` array
 *                                     and compare its `min` value with that of the `breakpoint` parameter.
 *
 * @returns {boolean} - Returns `true` if `breakpoint`'s minimum value is less than or equal to the
 *                      minimum value of the breakpoint named `breakpointToMatch`. Returns `false` if
 *                      `breakpoint` is nullish or if its minimum value is more.
 *
 * @example
 * // Check if the 'sm' breakpoint is less than or equal to 'md'
 * isBreakpointOrSmaller({ name: 'sm', min: 0 }, 'md'); // Returns true
 *
 * // Check if the 'lg' breakpoint is less than or equal to 'md'
 * isBreakpointOrSmaller({ name: 'lg', min: 1024 }, 'md'); // Returns false
 */
function isBreakpointOrSmaller(
  breakpoint: Breakpoint | null,
  breakpointToMatch: string,
): boolean {
  if (breakpoint === null) {
    return false;
  }
  const isBreakpointOrSmaller =
    isNumber(breakpoint?.min) &&
    breakpoint.min <=
      breakpoints.filter(
        (breakpoint) => breakpoint.name === breakpointToMatch,
      )[0].min;

  return isBreakpointOrSmaller === true;
}

function isNumber(v: unknown): boolean {
  return typeof v === 'number' && !isNaN(v) && v !== Infinity;
}

export default isBreakpointOrSmaller;
